import { Button, Card, Checkbox, Form, Input, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import { DatePickerAutoAccept } from '../lib/shared/components';
import {
    normalizeFin,
    normalizeIban,
    ibanValidator,
    legalAgeValidator,
} from '../lib/shared/validations';
import { Rate, RateVariantPicker } from '../rates';
import { Documents } from './documents';

const { Item } = Form;
const { Link } = Typography;

interface Props {
    rate: Rate;
    paymentMethod: DefaultOptionType | null | undefined;
    onFormFinish: (values: FormRules) => Promise<void>;
}

interface FormRules {
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    street: string;
    houseNumber: string;
    postalCode: string;
    place: string;
    email: string;
    telephoneNumber: string;
    vehicleIdentificationNumber: string;
    iban: string;
    privacyNoticeAcceepted: boolean;
    brokerContractAccepted: boolean;
}

export const DataGathering: React.FC<Props> = ({ rate, paymentMethod, onFormFinish }: Props) => {
    const [form] = Form.useForm<FormRules>();

    const [loading, setLoading] = React.useState<boolean>(false);

    const onFinish = async (values: FormRules): Promise<void> => {
        setLoading(true);
        try {
            await form.validateFields();
            await onFormFinish(values);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ flexGrow: 1, maxWidth: '1200px' }}>
            <Card>
                <Form form={form} requiredMark={false} layout='vertical' onFinish={onFinish}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                minWidth: '360px',
                                padding: '0 2%',
                                width: '48%',
                            }}
                        >
                            <Typography
                                style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}
                            >
                                Persönliche Angaben
                            </Typography>
                            <Item
                                name='firstName'
                                label='Vorname'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Vornamen eintragen',
                                    },
                                ]}
                                style={{ width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={40} />
                            </Item>
                            <Item
                                name='lastName'
                                label='Nachname'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Nachnamen eintragen',
                                    },
                                ]}
                                style={{ margin: '0 24px', width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={40} />
                            </Item>
                            <Item
                                name='dateOfBirth'
                                label='Geburtsdatum'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte Geburtsdatum eintragen',
                                    },
                                    {
                                        validator: (_, value) => legalAgeValidator(value),
                                        message:
                                            'Das Mindestalter für diesen Antrag beträgt 18 Jahre',
                                    },
                                ]}
                                style={{ width: '100%' }}
                            >
                                <DatePickerAutoAccept
                                    format={'DD.MM.YYYY'}
                                    placeholder='TT.MM.JJJJ'
                                    style={{ width: 'calc(50% - 24px)' }}
                                />
                            </Item>
                            <Item
                                name='street'
                                label='Straße'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Straße eintragen',
                                    },
                                ]}
                                style={{ width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={44} />
                            </Item>
                            <Item
                                name='houseNumber'
                                label='Hausnummer'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte Hausnummer eintragen',
                                    },
                                ]}
                                style={{ margin: '0 24px', width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={5} />
                            </Item>
                            <Item
                                name='postalCode'
                                label='Postleitzahl'
                                normalize={(input: string): string => input.replace(/\D/g, '')}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte eine Postleitzahl eintragen',
                                    },
                                    {
                                        pattern: /^[\d]{5}$/,
                                        message: 'Ungültige Postleitzahl',
                                    },
                                ]}
                                style={{ width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={5} />
                            </Item>
                            <Item
                                name='place'
                                label='Ort'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Ort eintragen',
                                    },
                                ]}
                                style={{ margin: '0 24px', width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={50} />
                            </Item>
                            <Item
                                name='email'
                                label='E-Mail'
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: 'Bitte eine gültige E-Mail Adresse eintragen',
                                    },
                                ]}
                                style={{ width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={80} />
                            </Item>
                            <Item
                                name='telephoneNumber'
                                label='Telefon'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Telefonnummer eintragen',
                                    },
                                ]}
                                style={{ margin: '0 24px', width: 'calc(50% - 24px)' }}
                            >
                                <Input maxLength={35} />
                            </Item>
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    fontWeight: '600',
                                    marginTop: '24px',
                                    width: '100%',
                                }}
                            >
                                {'Angaben zum Fahrzeug'}
                            </Typography>
                            <Item
                                name='vehicleIdentificationNumber'
                                label='FIN'
                                normalize={(value: string): string => normalizeFin(value)}
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message:
                                            'Bitte eine 17 Zeichen lange Fahrzeug-Identifizierungsnummer eintragen',
                                    },
                                ]}
                                style={{ width: 'calc(100% - 24px)' }}
                            >
                                <Input maxLength={17} />
                            </Item>
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    fontWeight: '600',
                                    marginTop: '24px',
                                    width: '100%',
                                }}
                            >
                                {'Zahlung '}
                                <b>{'S€PA'}</b>
                            </Typography>

                            <Item
                                name='iban'
                                label='IBAN'
                                normalize={(value: string): string => normalizeIban(value, true)}
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message:
                                            'Bitte eine gültige 22 Zeichen lange IBAN eintragen',
                                        validator: (_, value: string) =>
                                            ibanValidator('DE' + value),
                                    },
                                ]}
                                style={{ width: 'calc(100% - 24px)' }}
                            >
                                <Input
                                    maxLength={20}
                                    addonBefore={
                                        <Item noStyle>
                                            <div>DE</div>
                                        </Item>
                                    }
                                />
                            </Item>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                minWidth: '360px',
                                padding: '0 2%',
                                width: '48%',
                                marginTop: '24px',
                            }}
                        >
                            <Typography
                                style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}
                            >
                                Versicherungsprämie
                            </Typography>
                            <RateVariantPicker
                                rates={[rate]}
                                showVariantHeader={false}
                                paymentMethod={paymentMethod}
                                style={{ height: '50%', width: '100%' }}
                            />
                            <Item
                                name='privacyNoticeAcceepted'
                                valuePropName='checked'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte die Datenschutzhinweise akzeptieren',
                                        validator: (_, value) =>
                                            value === true ? Promise.resolve() : Promise.reject(),
                                    },
                                ]}
                                style={{ width: '100%' }}
                            >
                                <Checkbox style={{ marginTop: '24px' }}>
                                    {'Hiermit bestätige ich, dass ich die '}
                                    <Link href={Documents.agb} target='_blank'>
                                        {'AGB'}
                                    </Link>
                                    {', '}
                                    <Link href={Documents.cancellationPolicy} target='_blank'>
                                        {'Widerrufsbelehrung'}
                                    </Link>
                                    {', '}
                                    <Link href={Documents.privacyPolicy} target='_blank'>
                                        {'Datenschutzhinweise'}
                                    </Link>
                                    {' und '}
                                    <Link href={Documents.mediationRegulation} target='_blank'>
                                        {
                                            'Erstinformationen gemäß § 15 Versicherungsvermittlungsverordnung '
                                        }
                                    </Link>
                                    {'zur Kenntnis genommen habe.'}
                                </Checkbox>
                            </Item>
                            <Item
                                name='brokerContractAccepted'
                                valuePropName='checked'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte den Maklerauftrag erteilen',
                                        validator: (_, value) =>
                                            value === true ? Promise.resolve() : Promise.reject(),
                                    },
                                ]}
                                style={{ width: '100%' }}
                            >
                                <Checkbox>
                                    {
                                        'Hiermit erteilen wir der Firma SG IFFOXX Assekuranzmaklergesellschaft mbH mit sofortiger Wirkung den Maklerauftrag für die Verwaltung unserer Kfz-Versicherungsverträge.'
                                    }
                                </Checkbox>
                            </Item>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '1 0 0' }} />
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={loading}
                            style={{
                                alignSelf: 'right',
                                marginTop: '12px',
                            }}
                        >
                            {'Angebot annehmen ⟶'}
                        </Button>
                    </div>
                </Form>
            </Card>
        </div>
    );
};
